<template>
  <div class="language-bar flex">
    <button v-for="({lang, icon}, index) in languages"
            :key="index"
            class="flex-center"
            :class="{selected: language === lang}"
            @click="handleLanguage(lang)">
      <img :src="icon"
           :alt="lang" />
    </button>
  </div>
</template>

<script setup>
  import {useStore} from "vuex";
  import {computed, ref} from "vue";
  import ro from '../assets/flags/ro.svg'
  import en from '../assets/flags/en.svg'
  import fr from '../assets/flags/fr.svg'
  // import de from '../assets/flags/de.svg'

  const { state, dispatch, commit } = useStore()
  const isBarOpen = ref(false);
  const language = computed(() => state.language)
  const languages = [
    {lang: 'ro', icon: ro},
    {lang: 'en', icon: en},
    {lang: 'fr', icon: fr},
    // {lang: 'de', icon: de},
  ]

  function handleLanguage (lang) {
    commit('SET_LANGUAGE', lang)
    setTimeout(() => {
      isBarOpen.value = false;
    }, 200)
  }
</script>

<style lang="scss">
  .language-bar {
    @media only screen and (min-width: 0) {
      button {
        &:not(:last-child) {
          margin-right: 12px;
        }
        padding: 0;
        border: none;
        background-color: transparent;
        cursor: pointer;
        opacity: 0.6;
        img {
          height: 22px;
          border-radius: 4px;
        }
        &.selected {
          opacity: 1;
        }
      }
    }

    @media only screen and (min-width: 768px) {
      position: relative;
      transform: translateX(0);
      bottom: 0;
      background-color: transparent;
      border: none;
    }
  }
</style>